'use strict';

Gri.module({
  name: 'header-alpha',
  ieVersion: null,
  $el: $('.header-alpha'),
  container: '.header-alpha',
  fn: function () {
    tofas.headerAlpha = {
      el: $('.header-alpha'),
      window: $(window),
      init: function () {
        tofas.headerAlpha.hover();
        tofas.headerAlpha.sticky();
      },
      hover: function () {
        tofas.headerAlpha.el.hover(function(){
          tofas.headerAlpha.add("hover");
        },function(){
          tofas.headerAlpha.remove("hover");
        });
      },
      sticky: function () {
        var st = tofas.headerAlpha.window.scrollTop();
        if(st >= 30){
          tofas.headerAlpha.add("sticky");
        }else{
          tofas.headerAlpha.remove("sticky");
        }
        tofas.headerAlpha.window.on('scroll', function(){
          var st = tofas.headerAlpha.window.scrollTop();
          if(st >= 30){
            tofas.headerAlpha.add("sticky");
          }else{
            tofas.headerAlpha.remove("sticky");
          }
        });
      },
      add: function (className) {
        tofas.headerAlpha.el.addClass(className);
      },
      remove: function (className) {
        tofas.headerAlpha.el.removeClass(className);
      }
    }

    /**/

    tofas.headerAlpha.init();

  }
});
